import { ApiHrGetVacationApplicationsParameters } from "@/api/hr/types/vacationApplication/apiHrGetVacationApplicationsParameters";
import HrVacationApplicationsState
	from "@/store/hr/modules/vacationApplications/types/hrVacationApplicationsState";

export default class HrVacationApplicationsMapper {
	static mapToGetApiHrGetVacationApplicationsParameters(source: HrVacationApplicationsState): ApiHrGetVacationApplicationsParameters {
		return {
			id: "",
			vacationTypes: source.filter.vacationTypes,
			states: source.filter.applicationStates,
			employeeIds: source.filter.employeeIds
		};
	}
}
