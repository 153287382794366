import { HrCreateVacationApplicationRequest } from "@/types/hr/vacationApplication/hrCreateVacationApplicationRequest";
import { convertToZonedIso, formatDate } from "@/utils/dates";
import { isoDateFormat } from "@/utils/formats";

export interface ApiHrGetSubstitutesParameters {
	dateStart: string;
	dateEnd: string;
}

export class ApiHrGetSubstitutesParametersService {
	static map(source: HrCreateVacationApplicationRequest): ApiHrGetSubstitutesParameters {
		return {
			dateStart: formatDate(source.startDate, isoDateFormat) as string,
			dateEnd: formatDate(source.endDate, isoDateFormat) as string
		};
	}
}
