import { ApiHrDocument } from "@/api/hr/types/apiHrDocument";
import { ApiHrVacationApplicationEmployee } from "@/api/hr/types/vacationApplication/apiHrVacationApplicationEmployee";
import { ApiHrVacationApplicationSubstitute } from "@/api/hr/types/vacationApplication/apiHrVacationApplicationSubstitute";
import { convertToTimestamp } from "@/utils/dates";
import { ApiHrVacationApplication } from "@/api/hr/types/vacationApplication/apiHrVacationApplication";
import {
	HrVacationApplicationApproval,
	HrVacationApplicationApprovalService
} from "@/types/hr/vacationApplication/hrVacationApplicationApproval";
import { ApiHrVacationTypeEnum } from "@/api/hr/types/vacationApplication/ApiHrVacationTypeEnum";
import { ApiHrVacationApplicationStateEnum } from "@/api/hr/types/vacationApplication/ApiHrVacationApplicationStateEnum";

export interface HrVacationApplication {
	id: string;
	type: ApiHrVacationTypeEnum;
	state: ApiHrVacationApplicationStateEnum;
	startDate: number;
	endDate: number;
	vacationSubstitute?: ApiHrVacationApplicationSubstitute;
	approval?: HrVacationApplicationApproval;
	createdAt: number;
	employee: ApiHrVacationApplicationEmployee;
	documents: ApiHrDocument[];
	bloodDonationDate: number;
	educationInstitution: string;
	educationPurpose: string;
	educationDirection: string;
	warrantType: string;
	vacationDays: number[];
	referenceNumber: string;
	referenceSeries: string;
	referenceDay: number;
}

export class HrVacationApplicationService {
	static map(source: ApiHrVacationApplication): HrVacationApplication {
		return {
			...source,
			startDate: convertToTimestamp(source.startDate) as number,
			endDate: convertToTimestamp(source.endDate) as number,
			createdAt: convertToTimestamp(source.createdAt) as number,
			approval: source.approval && HrVacationApplicationApprovalService.map(source.approval),
			bloodDonationDate: convertToTimestamp(source.day) as number,
			vacationDays: source.vacationDays && source.vacationDays.map(x => convertToTimestamp(x) as number),
			referenceDay: convertToTimestamp(source.referenceDay) as number
		};
	}
}
