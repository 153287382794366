import SubscribersManager from "@/store/manager/subscribersManager";
import { actionTypes, getterTypes, mutationTypes, namespace } from "@/store/hr/modules/vacationApplications/types";
import { actionTypes as rootActionTypes } from "@/store/hr/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import SortingMixinBuilder from "@/store/shared/sorting";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import ListingMixinBuilder from "@/store/shared/listing";
import ListingModel from "@/store/shared/listing/models/listingModel";
import PagingMixinBuilder from "@/store/shared/paging";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchMixinBuilder from "@/store/shared/search";
import SearchModel from "@/store/shared/search/models/searchModel";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import { ActionTree, GetterTree, MutationPayload, MutationTree, Store } from "vuex";
import BatchService from "@/services/batchService";
import RouteMixinBuilder from "@/store/shared/route";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import AbortService from "@/services/abortService";
import { RouteNames } from "@/router/hr/routes";
import router from "@/router/hr";
import routeTypes from "@/store/shared/route/types";
import { formatFullName } from "@/utils/formatting";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";
import { HrController } from "@/api/hr";
import { ApiHrGetEmployeesParameters } from "@/api/hr/types/apiHrGetEmployeesParameters";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import HrVacationApplicationsFilter
	from "@/store/hr/modules/vacationApplications/types/hrVacationApplicationsFilter";
import HrVacationApplicationsState
	from "@/store/hr/modules/vacationApplications/types/hrVacationApplicationsState";
import HrVacationApplicationsRouteQuery
	from "@/store/hr/modules/vacationApplications/types/hrVacationApplicationsRouteQuery";
import HrVacationApplicationsRouteService
	from "@/store/hr/modules/vacationApplications/services/hrVacationApplicationsRouteService";
import { HrVacationApplication, HrVacationApplicationService } from "@/types/hr/vacationApplication/hrVacationApplication";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import { HrVacationApplicationController } from "@/api/hr/vacationApplication";
import HrVacationApplicationsMapper from "@/store/hr/modules/vacationApplications/mapper";
import { HrStorageController } from "@/api/hr/storage";
import { saveAs } from "file-saver";
import { i18n } from "@/plugins";
import { ApiHrVacationApplicationStateEnum } from "@/api/hr/types/vacationApplication/ApiHrVacationApplicationStateEnum";
import UserState from "@/store/hr/modules/user/types/userState";
import storeManager from "@/store/manager";
import { ApiHrRoleEnum } from "@/api/hr/types/ApiHrRoleEnum";
import userTypes from "@/store/hr/modules/user/types";
import { ApiHrEmployee } from "@/api/hr/types/apiHrEmployee";
import { cloneDeep } from "lodash";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";

const abortService = new AbortService();
const hrController = new HrController(abortService);
const hrVacationApplicationController = new HrVacationApplicationController(abortService);
const hrStorageController = new HrStorageController(abortService);

const defaultRouteQuery = new HrVacationApplicationsRouteQuery();

const routeService = new HrVacationApplicationsRouteService(defaultRouteQuery);

const updateListingBatchService = new BatchService(({ interval: 100 }));

const routeMixin = (new RouteMixinBuilder<HrVacationApplicationsState>()).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new HrVacationApplicationsState(
			new ListingModel<HrVacationApplication>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<string>({
				type: "",
				order: sortingOrderType.descending
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			}),
			new HrVacationApplicationsFilter(),
			routeMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

const state = (new DefaultStateBuilder()).build();

let subscribersManager: SubscribersManager<HrVacationApplicationsState>;

const getters = <GetterTree<HrVacationApplicationsState, any>>{
	...listingMixin.getters,
	[getterTypes.formattedItems]: (state) => {		
		return state.listing.items.map(x => {
			const approvedApprovers = x.approval?.approvers.filter(x => x.state === ApiHrVacationApplicationStateEnum.Approved) || [];
			const awaitingApprovalApprovers = x.approval?.approvers.filter(x => x.state !== ApiHrVacationApplicationStateEnum.Approved) || [];
			
			return {
				...x,
				createdAt: formatDate(x.createdAt, dateFormat),
				formattedType: i18n.t(`aliases.vacationType.${x.type}`),
				employeeName: formatFullName(x.employee),
				period: `${formatDate(x.startDate, dateFormat)}-${formatDate(x.endDate, dateFormat)}`,
				substituteName: x.vacationSubstitute && formatFullName(x.vacationSubstitute),
				approvedEmployees: approvedApprovers.map(x => formatFullName(x.employee)),
				awaitingApprovalEmployees: awaitingApprovalApprovers.map(x => formatFullName(x.employee))
			}
		});
	},
	[getterTypes.currentUser]: (state, getters, rootState) => {
		return resolveNestedState<UserState>(rootState, storeManager.hr.user.namespace).user;
	},
	[getterTypes.isAdmin]: (state, getters, rootState) => {
		const roles = resolveNestedState<UserState>(rootState, storeManager.hr.user.namespace).roles;
		
		return roles.includes(ApiHrRoleEnum.Administrator);
	}
};

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<HrVacationApplicationsState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	const namespace = storeManager.hr.vacationApplications.namespace;
	let state = resolveNestedState<HrVacationApplicationsState>(rootState, namespace);

	switch (mutation.type) {
		case resolveMutation(routeTypes.namespace, routeTypes.mutationTypes.ROUTE_CHANGED):
			if ((mutation.payload.from.name === mutation.payload.to.name) && !state.route.isPushing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRoute));
			break;
		case resolveMutation(namespace, mutationTypes.SET_FILTER_VACATION_TYPES):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_APPLICATION_STATES):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_EMPLOYEE_IDS):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_IS_ALL_EMPLOYEES):
		case resolveMutation(namespace, mutationTypes.SET_FILTER): {
			if (!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));

			updateListingBatchService.push(async () => {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.updateListingItems));
			});

			break;
		}
		case resolveMutation(userTypes.namespace, userTypes.mutationTypes.SET_USER_ROLES):
		{
			const roles = mutation.payload as ApiHrRoleEnum[];
			
			if(roles.includes(ApiHrRoleEnum.Administrator))
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.setAdminDefaultFilter));

			break;
		}
	}
};

const actions = <ActionTree<HrVacationApplicationsState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...routeMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state }) {
		await dispatch(actionTypes.initializeBase);
		
		unsubscribeCallback = subscribersManager.subscribe(subscribe);

		await Promise.all([
			dispatch(actionTypes.fetchEmployees),
			dispatch(actionTypes.fetchYears)
		])

		await dispatch(actionTypes.processRoute);
		await dispatch(actionTypes.reconstituteRoute);

		commit(mutationTypes.SET_IS_INITIALIZED, true);

		await dispatch(actionTypes.setAdminDefaultFilter);
	},
	async [actionTypes.updateListingItems]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);

		try {
			let items = await hrVacationApplicationController.getApplications(HrVacationApplicationsMapper.mapToGetApiHrGetVacationApplicationsParameters(state));

			commit(mutationTypes.SET_LISTING_ITEMS, items.map(x => HrVacationApplicationService.map(x)));
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if (error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.processRoute]({ rootState, commit, dispatch, state }) {
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, true);

		let routeQuery = await routeService.resolveRouteQuery(rootState.route.query);
		
		commit(mutationTypes.SET_FILTER_VACATION_TYPES, routeQuery.vacationTypes);
		commit(mutationTypes.SET_FILTER_APPLICATION_STATES, routeQuery.applicationStates);
		commit(mutationTypes.SET_FILTER_EMPLOYEE_IDS, routeQuery.employeeIds);
		commit(mutationTypes.SET_FILTER_IS_ALL_EMPLOYEES, routeQuery.isAllEmployees);

		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, false);
	},
	async [actionTypes.pushRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);
		
		await router.push({
			name: RouteNames.VACATION_APPLICATIONS,
			query: routeService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.reconstituteRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.replace({
			name: RouteNames.VACATION_APPLICATIONS,
			query: routeService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	},
	async [actionTypes.fetchEmployees]({ commit, state, dispatch }) {
		if (state.employees.length > 0)
			return;

		commit(mutationTypes.SET_IS_EMPLOYEES_LOADING, true);

		try {
			const { employees } = await hrController.getEmployees({} as ApiHrGetEmployeesParameters);

			commit(mutationTypes.SET_EMPLOYEES, employees.map(x => ({ ...x, fullName: formatFullName(x) })));
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if (error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_EMPLOYEES_LOADING, false);
		}
	},
	async [actionTypes.downloadCertificatesStampDocument]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_CERTIFICATES_STAMP_DOCUMENT_DOWNLOADING, true);

		try {
			const document = state.currentOpenedDocument;

			if(!document?.certificatesStampFileId)
				return alertService.addError(AlertKeys.REQUEST_UNKNOWN_ERROR);

			const file = await hrStorageController.getFile(document.certificatesStampFileId);

			saveAs(file, document.title);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if (error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_CERTIFICATES_STAMP_DOCUMENT_DOWNLOADING, false);
		}
	},
	async [actionTypes.downloadPdfDocument]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_PDF_DOCUMENT_DOWNLOADING, true);

		try {
			const document = state.currentOpenedDocument;

			if(!document?.fileId)
				return alertService.addError(AlertKeys.REQUEST_UNKNOWN_ERROR);

			const file = await hrStorageController.getFile(document.fileId);

			saveAs(file, document.title);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if (error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_PDF_DOCUMENT_DOWNLOADING, false);
		}
	},
	async [actionTypes.setAdminDefaultFilter]({ commit, state, getters }) {
		const currentUser = getters[getterTypes.currentUser] as ApiHrEmployee;
		
		if(!state.isInitialized || !currentUser?.id)
			return;
		
		if(getters[getterTypes.isAdmin]) {
			const defaultRouteQuery = new HrVacationApplicationsRouteQuery([currentUser.id]);

			if(!state.filter.isAllEmployees && !state.filter.employeeIds.length)
				commit(mutationTypes.SET_FILTER_EMPLOYEE_IDS, [currentUser.id]);

			routeService.setDefaultRoute(defaultRouteQuery);
		} else {
			commit(mutationTypes.SET_FILTER_EMPLOYEE_IDS, []);
		}
	},
	async [actionTypes.fetchYears]({ commit, getters, dispatch }) {
		commit(mutationTypes.SET_IS_YEARS_LOADING, true);
		
		try {
			const years = await hrController.getVacationPlanYears();
			
			commit(mutationTypes.SET_YEARS, years);
		} catch (error) {
			if(error.constructor === HttpNotFoundException)
				return;
			
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_YEARS_LOADING, false);
		}
	}
};

const mutations = <MutationTree<HrVacationApplicationsState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...routeMixin.mutations,
	[mutationTypes.SET_FILTER_VACATION_TYPES](state, value) {
		state.filter.vacationTypes = value;
	},
	[mutationTypes.SET_FILTER_APPLICATION_STATES](state, value) {
		state.filter.applicationStates = value;
	},
	[mutationTypes.SET_FILTER_EMPLOYEE_IDS](state, value) {
		state.filter.employeeIds = value;
	},
	[mutationTypes.SET_FILTER_IS_ALL_EMPLOYEES](state, value) {
		state.filter.isAllEmployees = value;
	},
	[mutationTypes.SET_FILTER](state, value) {
		state.filter = value;
	},
	[mutationTypes.SET_IS_EMPLOYEES_LOADING](state, value) {
		state.isEmployeesLoading = value;
	},
	[mutationTypes.SET_EMPLOYEES](state, value) {
		state.employees = value;
	},
	[mutationTypes.SET_CURRENT_OPENED_DOCUMENT](state, value) {
		state.currentOpenedDocument = cloneDeep(value);
	},
	[mutationTypes.SET_CURRENT_OPENED_APPLICATION](state, value) {
		state.currentOpenedApplication = cloneDeep(value);
	},
	[mutationTypes.SET_IS_PDF_DOCUMENT_DOWNLOADING](state, value) {
		state.isPdfDocumentDownloading = value;
	},
	[mutationTypes.SET_IS_CERTIFICATES_STAMP_DOCUMENT_DOWNLOADING](state, value) {
		state.isCertificatesStampDocumentDownloading = value;
	},
	[mutationTypes.SET_IS_YEARS_LOADING](state, value) {
		state.isYearsLoading = value;
	},
	[mutationTypes.SET_YEARS](state, value) {
		state.years = cloneDeep(value);
	}
};

export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const hrVacationApplicationsModule = {
	namespace, state, getters, actions, mutations, initializeSubscribersManager, namespaced: true
};

export default hrVacationApplicationsModule;
