import { HrCreateVacationApplicationRequest } from "@/types/hr/vacationApplication/hrCreateVacationApplicationRequest";
import { formatDate } from "@/utils/dates";
import { isoDateFormat } from "@/utils/formats";

export interface ApiHrCreateStudyVacationApplicationRequest {
	vacationSubstituteEmployeeId: string;
	startDate: string;
	endDate: string;
	callCertificateTempFileId: string;
	educationInstitution: string;
	educationPurpose: string;
	educationDirection: string;
}

export class ApiHrCreateStudyVacationApplicationRequestService {
	static map(source: HrCreateVacationApplicationRequest): ApiHrCreateStudyVacationApplicationRequest {
		return {
			vacationSubstituteEmployeeId: source.vacationSubstituteEmployeeId,
			startDate: formatDate(source.startDate, isoDateFormat) as string,
			endDate: formatDate(source.endDate, isoDateFormat) as string,
			callCertificateTempFileId: source.tempApplicationFileId,
			educationInstitution: source.educationInstitution,
			educationPurpose: source.educationPurpose,
			educationDirection: source.educationDirection
		};
	}
}
