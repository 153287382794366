import { HrCreateVacationApplicationRequest } from "@/types/hr/vacationApplication/hrCreateVacationApplicationRequest";
import { formatDate } from "@/utils/dates";
import { isoDateFormat } from "@/utils/formats";

export interface ApiHrCreateClinicalExaminationVacationApplicationRequest {
	warrantType: string;
	startDate: string;
	endDate: string;
	pfrPensionTempDocumentId: string;
}

export class ApiHrCreateClinicalExaminationVacationApplicationRequestService {
	static map(source: HrCreateVacationApplicationRequest): ApiHrCreateClinicalExaminationVacationApplicationRequest {
		return {
			warrantType: source.warrantType,
			startDate: formatDate(source.startDate, isoDateFormat) as string,
			endDate: formatDate(source.endDate, isoDateFormat) as string,
			pfrPensionTempDocumentId: source.tempApplicationFileId
		};
	}
}
