import { HrCreateVacationApplicationRequest } from "@/types/hr/vacationApplication/hrCreateVacationApplicationRequest";
import { formatDate } from "@/utils/dates";
import { isoDateFormat } from "@/utils/formats";

export interface ApiHrCreateOtherVacationApplicationRequest {
	vacationSubstituteEmployeeId: string;
	startDate: string;
	endDate: string;
	tempApplicationFileId: string;
}

export class ApiHrCreateOtherVacationApplicationRequestService {
	static map(source: HrCreateVacationApplicationRequest): ApiHrCreateOtherVacationApplicationRequest {
		return {
			vacationSubstituteEmployeeId: source.vacationSubstituteEmployeeId,
			tempApplicationFileId: source.tempApplicationFileId,
			startDate: formatDate(source.startDate, isoDateFormat) as string,
			endDate: formatDate(source.endDate, isoDateFormat) as string
		};
	}
}
