import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/hr/modules/vacationApplication/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import AbortService from "@/services/abortService";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";
import { HrVacationApplicationController } from "@/api/hr/vacationApplication";
import VacationApplicationState from "@/store/hr/modules/vacationApplication/types/vacationApplicationState";
import FormMixinBuilder from "@/store/shared/form";
import { cloneDeep } from "lodash";
import { actionTypes as rootActionTypes } from "@/store/hr/types";
import { HrVacationApplicationService } from "@/types/hr/vacationApplication/hrVacationApplication";
import { resolveNestedState } from "@/utils/vuexModules";
import UserState from "@/store/hr/modules/user/types/userState";
import storeManager from "@/store/manager";
import { ApiHrEmployee } from "@/api/hr/types/apiHrEmployee";
import { ApiHrGetSubstitutesParametersService } from "@/api/hr/types/vacationApplication/apiHrGetSubstitutesParameters";
import { HrController } from "@/api/hr";
import { ApiHrVacationTypeEnum } from "@/api/hr/types/vacationApplication/ApiHrVacationTypeEnum";
import {
	ApiHrCreatePlannedVacationApplicationRequestService
} from "@/api/hr/types/vacationApplication/apiHrCreatePlannedVacationApplicationRequest";
import {
	ApiHrCreateOwnExpenseVacationApplicationRequestService
} from "@/api/hr/types/vacationApplication/apiHrCreateOwnExpenseVacationApplicationRequest";
import { ApiHrVacationApplication } from "@/api/hr/types/vacationApplication/apiHrVacationApplication";
import {
	ApiHrCreateOtherVacationApplicationRequestService
} from "@/api/hr/types/vacationApplication/apiHrCreateOtherVacationApplicationRequest";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import router from "@/router/hr";
import { RouteNames } from "@/router/hr/routes";
import { HrAvailableVacationService } from "@/types/hr/vacationApplication/hrAvailableVacation";
import { ApiHrGetEmployeesParameters } from "@/api/hr/types/apiHrGetEmployeesParameters";
import { formatFullName } from "@/utils/formatting";
import {
	ApiHrCreateClinicalExaminationVacationApplicationRequestService
} from "@/api/hr/types/vacationApplication/apiHrCreateClinicalExaminationVacationApplicationRequest";
import {
	ApiHrCreateStudyVacationApplicationRequestService
} from "@/api/hr/types/vacationApplication/apiHrCreateStudyVacationApplicationRequest";
import {
	ApiHrCreateDonorVacationApplicationRequestService
} from "@/api/hr/types/vacationApplication/apiHrCreateDonorVacationApplicationRequest";

const abortService = new AbortService();
const hrVacationApplicationController = new HrVacationApplicationController(abortService);
const hrController = new HrController(abortService);

const baseMixin = (new BaseMixinBuilder(abortService)).build();
const formMixin = (new FormMixinBuilder()).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["request"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new VacationApplicationState(
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<VacationApplicationState, any>>{
	...formMixin.getters,
	...snapshotMixin.getters,
	[getterTypes.currentUser]: (state, getters, rootState) => {
		return resolveNestedState<UserState>(rootState, storeManager.hr.user.namespace).user;
	}
};

const actions = <ActionTree<VacationApplicationState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, getters, state }, { id, vacationId }) {
		await dispatch(actionTypes.initializeBase);
		
		await Promise.all([
			dispatch(actionTypes.fetchVacations),
			dispatch(actionTypes.fetchYears)
		]);

		if(id)
			await dispatch(actionTypes.fetch, { id });
		else if(vacationId) {
			const vacation = state.vacations.find(x => x.id === vacationId);

			if(vacation) {
				commit(mutationTypes.SET_REQUEST_VACATION_TYPE, ApiHrVacationTypeEnum.Planned);
				commit(mutationTypes.SET_REQUEST_VACATION_ID, vacation.id);
				commit(mutationTypes.SET_REQUEST_START_DATE, vacation.startDate);
				commit(mutationTypes.SET_REQUEST_END_DATE, vacation.endDate);

				await dispatch(actionTypes.fetchSubstitutes);
			}
		}

		commit(mutationTypes.SET_ID, id);
		commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.fetch]({ dispatch, commit, state }, { id }: { id: string }) {
		commit(mutationTypes.SET_IS_FORM_LOADING, true);

		try {
			let application = await hrVacationApplicationController.getApplication(id);

			if(!application)
				throw new HttpNotFoundException("Заявка не найдена");

			commit(mutationTypes.SET_APPLICATION, HrVacationApplicationService.map(application));
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_FORM_LOADING, false);
		}
	},
	async [actionTypes.fetchSubstitutes]({ dispatch, commit, getters, state }) {
		const currentUser = getters[getterTypes.currentUser] as ApiHrEmployee;

		if(!currentUser?.id)
			return;

		commit(mutationTypes.SET_IS_SUBSTITUTES_LOADING, true);

		try {

			let items = await hrVacationApplicationController.getSubstitutes(currentUser.id,
				ApiHrGetSubstitutesParametersService.map(state.request));

			commit(mutationTypes.SET_SUBSTITUTES, items);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_SUBSTITUTES_LOADING, false);
		}
	},
	async [actionTypes.save]({ dispatch, commit, state }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			if(state.id)
				return;

			let payload: any;
			let application: ApiHrVacationApplication | null = null;

			switch (state.request.vacationType) {
				case ApiHrVacationTypeEnum.Planned:
					payload = ApiHrCreatePlannedVacationApplicationRequestService.map(state.request);
					application = await hrVacationApplicationController.createPlannedVacationApplication(payload);
					break;
				case ApiHrVacationTypeEnum.OwnExpense:
					payload = ApiHrCreateOwnExpenseVacationApplicationRequestService.map(state.request);
					application = await hrVacationApplicationController.createOwnExpenseVacationApplication(payload);
					break;
				case ApiHrVacationTypeEnum.Study:
					payload = ApiHrCreateStudyVacationApplicationRequestService.map(state.request);
					application = await hrVacationApplicationController.createStudyVacationApplication(payload);
					break;
				case ApiHrVacationTypeEnum.Donor:
					payload = ApiHrCreateDonorVacationApplicationRequestService.map(state.request);
					application = await hrVacationApplicationController.createDonorVacationApplication(payload);
					break;
				case ApiHrVacationTypeEnum.ClinicalExamination:
					payload = ApiHrCreateClinicalExaminationVacationApplicationRequestService.map(state.request);
					application = await hrVacationApplicationController.createClinicalExaminationVacationApplication(payload);
					break;
				case ApiHrVacationTypeEnum.Other:
					payload = ApiHrCreateOtherVacationApplicationRequestService.map(state.request);
					application = await hrVacationApplicationController.createOtherVacationApplication(payload);
					break;
			}

			await hrVacationApplicationController.sendToReview(application!.id);

			alertService.addInfo(AlertKeys.VACATION_APPLICATION_SUCCESS_CREATED);

			if(application?.id)
				await router.push({ name: RouteNames.VACATION_APPLICATION, params: { id: application!.id } });
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.addApprover]({ dispatch, commit, state }, id) {
		commit(mutationTypes.SET_IS_APPROVER_ADDING, true);

		try {
			const application = await hrVacationApplicationController.addApprover(state.id, id);

			alertService.addInfo(AlertKeys.APPROVER_SUCCESS_ADDED);

			commit(mutationTypes.SET_APPLICATION, HrVacationApplicationService.map(application));
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_APPROVER_ADDING, false);
		}
	},
	async [actionTypes.removeApprover]({ dispatch, commit, state }, id) {
		commit(mutationTypes.SET_IS_APPROVER_REMOVING, true);

		try {
			const application = await hrVacationApplicationController.removeApprover(state.id, id);

			alertService.addInfo(AlertKeys.APPROVER_SUCCESS_REMOVED);

			commit(mutationTypes.SET_APPLICATION, HrVacationApplicationService.map(application));
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_APPROVER_REMOVING, false);
		}
	},
	async [actionTypes.fetchEmployees]({ commit, state, dispatch }) {
		if(state.employees.length > 0)
			return;

		commit(mutationTypes.SET_IS_EMPLOYEES_LOADING, true);

		try {
			const { employees } = await hrController.getEmployees({} as ApiHrGetEmployeesParameters);

			commit(mutationTypes.SET_EMPLOYEES, employees.map(x => ({ ...x, fullName: formatFullName(x) })));
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_EMPLOYEES_LOADING, false);
		}
	},
	async [actionTypes.fetchVacations]({ commit, getters, dispatch }) {
		commit(mutationTypes.SET_IS_VACATIONS_LOADING, true);

		try {
			const currentUser = getters[getterTypes.currentUser] as ApiHrEmployee;

			const items = await hrVacationApplicationController.getAvailableVacations(currentUser.id);

			commit(mutationTypes.SET_VACATIONS, items.map(x => HrAvailableVacationService.map(x)));
		} catch (error) {
			if(error.constructor === HttpNotFoundException)
				return;

			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_VACATIONS_LOADING, false);
		}
	},
	async [actionTypes.fetchYears]({ commit, dispatch }) {
		commit(mutationTypes.SET_IS_YEARS_LOADING, true);
		
		try {
			const years = await hrController.getVacationPlanYears();
			
			commit(mutationTypes.SET_YEARS, years);
		} catch (error) {
			if(error.constructor === HttpNotFoundException)
				return;
			
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_YEARS_LOADING, false);
		}
	}
};

const mutations = <MutationTree<VacationApplicationState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.SET_APPLICATION](state, value) {
		state.application = cloneDeep(value);
	},
	[mutationTypes.SET_APPLICATION_STATE](state, value) {
		state.application!.state = value;
	},
	[mutationTypes.ADD_APPLICATION_APPROVER](state, value) {
		const approval = state.application!.approval!;
		approval.approvers.push(cloneDeep(value));
	},
	[mutationTypes.REMOVE_APPLICATION_APPROVER](state, id) {
		const approval = state.application!.approval!;
		approval.approvers.splice(approval.approvers.findIndex(x => x.employee.id === id), 1);
	},
	[mutationTypes.SET_REQUEST](state, value) {
		state.request = cloneDeep(value);
	},
	[mutationTypes.SET_REQUEST_VACATION_ID](state, value) {
		state.request.plannedVacationId = value;
	},
	[mutationTypes.SET_REQUEST_START_DATE](state, value) {
		state.request.startDate = value;
	},
	[mutationTypes.SET_REQUEST_END_DATE](state, value) {
		state.request.endDate = value;
	},
	[mutationTypes.SET_REQUEST_SUBSTITUTE_ID](state, value) {
		state.request.vacationSubstituteEmployeeId = value;
	},
	[mutationTypes.SET_REQUEST_FILE_ID](state, value) {
		state.request.tempApplicationFileId = value;
	},
	[mutationTypes.SET_REQUEST_BLOOD_DONATION_DATE](state, value) {
		state.request.bloodDonationDate = value;
	},
	[mutationTypes.SET_REQUEST_EDUCATION_INSTITUTION](state, value) {
		state.request.educationInstitution = value;
	},
	[mutationTypes.SET_REQUEST_EDUCATION_PURPOSE](state, value) {
		state.request.educationPurpose = value;
	},
	[mutationTypes.SET_REQUEST_EDUCATION_DIRECTION](state, value) {
		state.request.educationDirection = value;
	},
	[mutationTypes.SET_REQUEST_WARRANT_TYPE](state, value) {
		state.request.warrantType = value;
	},
	[mutationTypes.SET_REQUEST_VACATION_DAYS](state, value) {
		state.request.vacationDays = value;
	},
	[mutationTypes.SET_REQUEST_REFERENCE_NUMBER](state, value) {
		state.request.referenceNumber = value;
	},
	[mutationTypes.SET_REQUEST_REFERENCE_SERIES](state, value) {
		state.request.referenceSeries = value;
	},
	[mutationTypes.SET_REQUEST_REFERENCE_DAY](state, value) {
		state.request.referenceDay = value;
	},
	[mutationTypes.SET_REQUEST_VACATION_TYPE](state, value) {
		state.request.vacationType = value;
	},
	[mutationTypes.SET_ID](state, value) {
		state.id = value;
	},
	[mutationTypes.SET_SUBSTITUTES](state, value) {
		state.substitutes = value;
	},
	[mutationTypes.SET_IS_SUBSTITUTES_LOADING](state, value) {
		state.isSubstitutesLoading = value;
	},
	[mutationTypes.SET_IS_APPROVER_ADDING](state, value) {
		state.isApproverAdding = value;
	},
	[mutationTypes.SET_IS_APPROVER_REMOVING](state, value) {
		state.isApproverRemoving = value;
	},
	[mutationTypes.SET_IS_EMPLOYEES_LOADING](state, value) {
		state.isEmployeesLoading = value;
	},
	[mutationTypes.SET_EMPLOYEES](state, value) {
		state.employees = value;
	},
	[mutationTypes.SET_IS_VACATIONS_LOADING](state, value) {
		state.isVacationsLoading = value;
	},
	[mutationTypes.SET_VACATIONS](state, value) {
		state.vacations = cloneDeep(value);
	},
	[mutationTypes.SET_IS_YEARS_LOADING](state, value) {
		state.isYearsLoading = value;
	},
	[mutationTypes.SET_YEARS](state, value) {
		state.years = cloneDeep(value);
	}
};

export {
	namespace, state, getters, actions, mutations
};

const hrVacationApplicationModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default hrVacationApplicationModule;
