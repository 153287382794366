import { IsArray, IsBoolean, IsString } from "class-validator";
import { ApiHrVacationTypeEnum } from "@/api/hr/types/vacationApplication/ApiHrVacationTypeEnum";
import { ApiHrVacationApplicationStateEnum } from "@/api/hr/types/vacationApplication/ApiHrVacationApplicationStateEnum";

export default class HrVacationApplicationsRouteQuery {
	@IsArray()
	@IsString({ each: true })
	employeeIds: string[];
	
	@IsArray()
	vacationTypes: ApiHrVacationTypeEnum[];

	@IsArray()
	applicationStates: ApiHrVacationApplicationStateEnum[];

	@IsBoolean()
	isAllEmployees: boolean;
	
	constructor(
		employeeIds: string[] = [],
		vacationTypes: ApiHrVacationTypeEnum[] = [],
		applicationStates: ApiHrVacationApplicationStateEnum[] = [],
		isAllEmployees: boolean = false
	) {
		this.employeeIds = employeeIds;
		this.vacationTypes = vacationTypes;
		this.applicationStates = applicationStates;
		this.isAllEmployees = isAllEmployees;
	}
}
