import { HrCreateVacationApplicationRequest } from "@/types/hr/vacationApplication/hrCreateVacationApplicationRequest";
import { formatDate } from "@/utils/dates";
import { isoDateFormat } from "@/utils/formats";

export interface ApiHrCreateDonorVacationApplicationRequest {
	day: string;
	vacationDays: string[];
	donorCertificateTempFileId: string;
	referenceNumber: string;
	referenceSeries: string;
	referenceDay: string;
}

export class ApiHrCreateDonorVacationApplicationRequestService {
	static map(source: HrCreateVacationApplicationRequest): ApiHrCreateDonorVacationApplicationRequest {
		return {
			day: formatDate(source.bloodDonationDate, isoDateFormat) as string,
			vacationDays: source.vacationDays.map(x => formatDate(x, isoDateFormat) as string),
			donorCertificateTempFileId: source.tempApplicationFileId,
			referenceNumber: source.referenceNumber,
			referenceSeries: source.referenceSeries,
			referenceDay: formatDate(source.referenceDay, isoDateFormat) as string
		};
	}
}
