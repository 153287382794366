import IPageState from "@/store/shared/base/types/pageState";
import {
	HrCreateVacationApplicationRequest,
	HrCreateVacationApplicationRequestService
} from "@/types/hr/vacationApplication/hrCreateVacationApplicationRequest";
import { ApiHrVacationTypeEnum } from "@/api/hr/types/vacationApplication/ApiHrVacationTypeEnum";
import FormState from "@/store/shared/form/models/formState";
import { ApiHrVacationApplicationSubstitute } from "@/api/hr/types/vacationApplication/apiHrVacationApplicationSubstitute";
import { HrVacationApplication } from "@/types/hr/vacationApplication/hrVacationApplication";
import { ApiHrEmployeesItem } from "@/api/hr/types/apiHrEmployeesItem";
import { HrAvailableVacation } from "@/types/hr/vacationApplication/hrAvailableVacation";
import { ApiHrVacationYear } from "@/api/hr/types/vacation/apiHrVacationYear";

export default class VacationApplicationState implements IPageState {
	constructor(
		public form: FormState,
		public snapshot: object,
		public application: HrVacationApplication | undefined = undefined,
		public request: HrCreateVacationApplicationRequest = HrCreateVacationApplicationRequestService.getEmpty(),
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public id: string = "",
		public type: ApiHrVacationTypeEnum | null = null,
		public substitutes: ApiHrVacationApplicationSubstitute[] = [],
		public isSubstitutesLoading: boolean = false,
		public isEmployeesLoading: boolean = false,
		public isApproverAdding: boolean = false,
		public isApproverRemoving: boolean = false,
		public isVacationsLoading: boolean = false,
		public isYearsLoading: boolean = false,
		public employees: ApiHrEmployeesItem[] = [],
		public vacations: HrAvailableVacation[] = [],
		public years: ApiHrVacationYear[] = []
	)
	{
	}
}
