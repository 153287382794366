import "reflect-metadata";
import Vue from "vue";
import App from "@/HrApp.vue";
import router from "@/router/hr";
import store from "@/store/hr";
import { i18n, vuetify } from "@/plugins";
import { sync } from "vuex-router-sync";
import "@/assets/main.css";
import configureApi from "@/api/index";
import PermissionsResolver from "@/api/authorization/permissionsResolver";
import { setEnvironmentVariablesInWindowEnv } from "@/utils/development/setEnvironmentVariablesInWindowEnvironment";

const permissionsResolver = new PermissionsResolver();
permissionsResolver.resetPermissionsStorage();

configureApi();

sync(store, router);
Vue.config.productionTip = false;

setEnvironmentVariablesInWindowEnv();

new Vue({
	i18n,
	router,
	store,
	vuetify,
	render: (h) => h(App)
}).$mount("#app");
