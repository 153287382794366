import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import RouteState from "@/store/shared/route/types/routeState";
import IPageState from "@/store/shared/base/types/pageState";
import { ApiHrEmployeesItem } from "@/api/hr/types/apiHrEmployeesItem";
import HrVacationApplicationsFilter
	from "@/store/hr/modules/vacationApplications/types/hrVacationApplicationsFilter";
import { HrVacationApplication } from "@/types/hr/vacationApplication/hrVacationApplication";
import { ApiHrDocument } from "@/api/hr/types/apiHrDocument";
import { ApiHrVacationYear } from "@/api/hr/types/vacation/apiHrVacationYear";

export default class HrVacationApplicationsState implements IPageState {
	constructor(
		public listing: ListingModel<HrVacationApplication>,
		public sorting: SortingModel<string>,
		public paging: PagingModel,
		public search: SearchModel,
		public filter: HrVacationApplicationsFilter,
		public route: RouteState,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isEmployeesLoading: boolean = false,
		public employees: ApiHrEmployeesItem[] = [],
		public currentOpenedDocument: ApiHrDocument | undefined = undefined,
		public currentOpenedApplication: HrVacationApplication | undefined = undefined,
		public isPdfDocumentDownloading: boolean = false,
		public isCertificatesStampDocumentDownloading: boolean = false,
		public isYearsLoading: boolean = false,
		public years: ApiHrVacationYear[] = [],
	)
	{
	}
}
