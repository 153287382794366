import { HrCreateVacationApplicationRequest } from "@/types/hr/vacationApplication/hrCreateVacationApplicationRequest";

export interface ApiHrCreatePlannedVacationApplicationRequest {
	vacationSubstituteEmployeeId: string;
	plannedVacationId: string;
}

export class ApiHrCreatePlannedVacationApplicationRequestService {
	static map(source: HrCreateVacationApplicationRequest): ApiHrCreatePlannedVacationApplicationRequest {
		return {
			vacationSubstituteEmployeeId: source.vacationSubstituteEmployeeId,
			plannedVacationId: source.plannedVacationId
		};
	}
}
