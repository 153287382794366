import { difference } from "@/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import { parseArrayParameter, parseBooleanParameter } from "@/utils/query";
import HrVacationApplicationsRouteQuery
	from "@/store/hr/modules/vacationApplications/types/hrVacationApplicationsRouteQuery";
import HrVacationApplicationsState
	from "@/store/hr/modules/vacationApplications/types/hrVacationApplicationsState";

export default class HrVacationApplicationsRouteService {
	initialRouteQuery: HrVacationApplicationsRouteQuery;
	defaultRouteQuery: HrVacationApplicationsRouteQuery;
	
	constructor(defaultRouteQuery: HrVacationApplicationsRouteQuery) {
		this.initialRouteQuery = defaultRouteQuery;
		this.setDefaultRoute(defaultRouteQuery);
	}

	setDefaultRoute(defaultRouteQuery: HrVacationApplicationsRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}
	
	resolveRouteQueryDictionary(state: HrVacationApplicationsState) {
		let query = new HrVacationApplicationsRouteQuery(
			state.filter.employeeIds,
			state.filter.vacationTypes,
			state.filter.applicationStates,
			state.filter.isAllEmployees
		);
		
		return difference(this.initialRouteQuery, query);
	}
	
	async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(HrVacationApplicationsRouteQuery, {
				...this.defaultRouteQuery,
				...query,
				employeeIds: parseArrayParameter(query.employeeIds),
				vacationTypes: parseArrayParameter(query.vacationTypes),
				applicationStates: parseArrayParameter(query.applicationStates),
				isAllEmployees: parseBooleanParameter(query.isAllEmployees, this.defaultRouteQuery.isAllEmployees)
			}, { enableImplicitConversion: true });

			await validateOrReject(result);
			
			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}
