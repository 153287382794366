import { ApiHrVacationTypeEnum } from "@/api/hr/types/vacationApplication/ApiHrVacationTypeEnum";

export interface HrCreateVacationApplicationRequest {
	startDate: number;
	endDate: number;
	vacationSubstituteEmployeeId: string;
	plannedVacationId: string;
	tempApplicationFileId: string;
	vacationType: ApiHrVacationTypeEnum | null;
	bloodDonationDate: number
	educationInstitution: string;
	educationPurpose: string;
	educationDirection: string;
	warrantType: string;
	vacationDays: number[];
	referenceNumber: string;
	referenceSeries: string;
	referenceDay: number;
}

export class HrCreateVacationApplicationRequestService {
	static getEmpty(): HrCreateVacationApplicationRequest {
		return {
			startDate: 0,
			endDate: 0,
			vacationSubstituteEmployeeId: "",
			plannedVacationId: "",
			tempApplicationFileId: "",
			vacationType: null,
			bloodDonationDate: 0,
			educationInstitution: "",
			educationPurpose: "",
			educationDirection: "",
			warrantType: "",
			vacationDays: [],
			referenceNumber: "",
			referenceSeries: "",
			referenceDay: 0
		};
	}
}
